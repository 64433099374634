import React from "react"
import value1 from "../images/values-1.png"
import value2 from "../images/values-2.png"
import value3 from "../images/values-3.png"
import "../styles/values.css"
import {FaRegSmileBeam,FaRegAddressBook,FaHeadphonesAlt,FaPeopleCarry} from 'react-icons/fa';
export default function Values(){
    return(
        <div>
<section id="values" class="values">

<div class="container" data-aos="fade-up">

  <header class="section-header">
    {/* <h2>Our Values</h2> */}
    <p>Creating Value For Your Business</p>
  </header>

  <div class="row">

    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
      <div class="box">
        <img src={value1} class="img-fluid" alt=""/>
        <h3>What we can do?</h3>
        <p>We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.</p>
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div class="box">
        <img src={value2} class="img-fluid" alt=""/>
        <h3>Creating Value</h3>
        <p>We have rapidly built a world-class team of engineers ready to take on diverse and challenging projects with an aim to create real value for our partners and community..</p>
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={value3} class="img-fluid" alt=""/>
        <h3>Proven Capability.</h3>
        <p>Give us a problem and we will engineer a solution! Our team of world-class engineers has developed enterprise and analytical software used by millions of users every day..</p>
      </div>
    </div>

  </div>

</div>

</section>
<section id="counts" class="counts">
      <div class="container" data-aos="fade-up">

        <div class="row gy-4">

          <div class="col-lg-3 col-md-6">
            <div class="count-box">
              {/* <i class="bi bi-emoji-smile"></i> */}
              <i><FaRegSmileBeam/></i>
              <div>
                <span>150+</span>
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
                <p>Happy Clients</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="count-box">
              {/* <i class="bi bi-journal-richtext" style="color: #ee6c20;"></i> */}
              <i><FaRegAddressBook class="projects"/></i>

              <div>
                <span>100+</span>
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
                <p>Projects</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i><FaHeadphonesAlt class="head"/></i>

              {/* <i class="bi bi-headset" style="color: #15be56;"></i> */}
              
              <div>
                <span>24</span>
                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter"></span>
                <p>Hours Of Support</p>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="count-box">
              <i><FaPeopleCarry class="people"/></i>

              {/* <i class="bi bi-people" style="color: #bb0852;"></i> */}
              <div>
                <span>20+</span>
                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
                <p>Employees</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
</div>
)}