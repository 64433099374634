import React from "react";
import "../styles/Home_services.css";
import service1 from "../images/51.jpg";
import service2 from "../images/services-2.jpg";
import service3 from "../images/graphic.png";
import service4 from "../images/services-4.jpg";
import features from "../images/features.png";
import ServicesInfo from "../pages/servicesInfo";
import {Link} from "react-router-dom"
function Home_Services() {
  return (
    <div>
      <section id="services" class="services section-bg">
        <div class="container">
          <div class="section-title">
            <h2 data-aos="fade-in">
              We take care of all the technology so you can focus on what you do
              best
            </h2>
            <p data-aos="fade-in">
              Our team of 100+ experienced software engineers, developers, UX
              architects, and designers like to produce impactful software. We
              create software that is used by millions of users, in sectors
              spanning Education, Technology, Healthcare, Travel and beyond. .
            </p>
          </div>

          <div class="row">
            <div class="col-md-6 d-flex" data-aos="fade-right">
              <div class="card">
                <div class="card-img">
                  <img src={service1} alt="..." />
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <p class="a">Web Development</p>
                  </h5>
                  <p class="card-text">
                    {" "}
                    We offer custom web app development using secure, robust
                    codebase that provides the seamless user experience modern
                    users expect.
                  </p>
                  <div class="read-more">
                    <p className="a">
                      <i class="bi bi-arrow-right"></i><Link to="/web"className="text-decoration-none">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex" data-aos="fade-left">
              <div class="card">
                <div class="card-img">
                  <img src={service2} alt="..." />
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <p className="a">Digital Marketing</p>
                  </h5>
                  <p class="card-text">
                  Promotion of brands to connect with potential customers using
                  the internet and other forms of digital communication.
                  </p>
                  <div class="read-more">
                    <p className="a">
                      <i class="bi bi-arrow-right"></i><Link to="/digital"className="text-decoration-none">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex" data-aos="fade-right">
              <div class="card">
                <div class="card-img">
                  <img src={service3} alt="..." />
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <p className="a">Graphic Designing</p>
                  </h5>
                  <p class="card-text">
                  A Code Solutions prides itself on it's high quality designers
                  who deliver only the best graphic design services. Find the
                  right design service for you below and get design you'll love
                  today
                  </p>
                  <div class="read-more">
                    <p className="a">
                      <i class="bi bi-arrow-right"></i><Link to="/graphic"className="text-decoration-none">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex" data-aos="fade-left">
              <div class="card">
                <div class="card-img">
                  <img src={service4} alt="..." />
                </div>
                <div class="card-body">
                  <h5 class="card-title">
                    <p className="a">Enterprise Application</p>
                  </h5>
                  <p class="card-text">
                  We’ve worked with Fortune 500 companies to develop custom
                  enterprise-wide software solutions. We specialize in building
                  precisely what you need and helping you transition from the
                  software you’ve outgrown to the new model.
                  </p>
                  <div class="read-more">
                    <p className="a">
                      <i class="bi bi-arrow-right"></i><Link to="/software"className="text-decoration-none">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Features</h2> */}
            <p>Technologies in which we are working on...</p>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={features} class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Angular</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>NodeJs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Laravell</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Wordpress</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>PHP</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home_Services;
