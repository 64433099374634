import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logo from "../images/asoftlogo.png";
import "../styles/Footer.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/fontawesome-free-solid'

export default function App() {
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className=" container d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span className="social-text text-primary fw-bold">
            Get connected with us on social networks:
          </span>
        </div>

        <div>
         {/* <a href="https://www.facebook.com/acodesolutions"> */}
            <MDBIcon fab icon="facebook-f" className="text-primary me-4"/>
          {/* </a> */}
          <Link to="/" className="me-4 text-reset ">
            <MDBIcon fab icon="twitter" className="text-primary"/>
          </Link>
         {/* <a href="https://www.instagram.com/acodesolutions/"> */}
            <MDBIcon fab icon="instagram" className="text-primary me-4"/>
          {/* </a> */}
          <Link to="/" className="me-4 text-reset">
            <MDBIcon fab icon="linkedin" className="text-primary"/>
          </Link>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <img src={logo} alt="" height={80} width={220} />
              <p className="mt-3">
                {/* <FontAwesomeIcon icon={faCoffee} /> */}
                "A Soft Solution" specializes in technological and IT-related
                services such as Webdevelopment , Application development,
                software development , product engineering, warranty management,
                building cloud, infrastructure, network, etc
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-primary">
                <MDBIcon icon="desktop" className="me-2 text-primary" />
                IT Serivces
              </h6>
              <p>
                <Link to="/services" className="text-reset">
                  Web Development
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  App Development
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  Digital Marketing
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  Cloud Computing
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  Google SEO
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  IT Consultancy
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-primary">
                <MDBIcon icon="home" className="me-2 text-primary" />
                Quick links
              </h6>
              <p>
                <Link to="/" className="text-reset">
                  Home
                </Link>
              </p>
              <p>
                <Link to="/about" className="text-reset">
                  Why A Soft Solution
                </Link>
              </p>
              <p>
                <Link to="/services" className="text-reset">
                  Services
                </Link>
              </p>
              <p>
                <Link to="/franchise" className="text-reset">
                  Offices
                </Link>
              </p>
              <p>
                <Link to="/projects" className="text-reset">
                  Our Projects
                </Link>
              </p>
              <p>
                <Link to="/about" className="text-reset">
                  About
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-primary">
                <MDBIcon icon="headset" className="me-2 text-primary" />
                Contact
              </h6>
              <p>
                <MDBIcon icon="home" className="me-2 text-primary" />
                A Soft Solution , Bolan Block ,DC Colony, Gujranwala, Pakistan
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3 text-primary" />
                info@asoftsolution.com
                
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3 text-primary" /> 0300 4843092
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3 text-primary" /> 055-2110887
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4 text-primary"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        © 2024 Copyright:
        <a className="text-reset fw-bold text-primary" href="https://asoftsolution.com/">
          Designed by A Soft Solution
        </a>
      </div>
    </MDBFooter>
  );
}
