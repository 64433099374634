
import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../images/asoftlogo.png"
import  {Link}  from 'react-router-dom';
import "../styles/Header.css"
import "../index.css"

function NavBar() {
  const styleLink={
    textDecoration:"none"
  }
  return (
   <header class="fixed-top bg-white" id=" fixed-top">
    <div class="text-light">
    <Navbar className="text-dark"collapseOnSelect expand="lg" bg="" variant="dark">
  
        {/* <Navbar.Brand><Link to="/" className="text-light text-decoration-none navIcon me-auto">Code Solution</Link></Navbar.Brand> */}
        <Container>
          <Navbar.Brand className=""><Link to="/" class="m-0 p-0">
            <img
              src={Logo}
              width="160"
              height="50"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            </Link>
          </Navbar.Brand>
   
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="bg-info"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto navbar">
            <li>
            <Nav.Link><Link to="/"className="  text-decoration-none navIcon">Home</Link></Nav.Link>
            </li>
            <Nav.Link><Link to="/about"className="  text-decoration-none navIcon">About</Link></Nav.Link>
            {/* <Nav.Link><Link to="/services"className="  text-decoration-none navIcon">Services</Link></Nav.Link> */}
            <Nav.Link class="p-0">
              <li class="dropdown nav-link scrollto  text-decoration-none navIcon  p-0">
                <div className="a">
                  <Link to="/services" class="navIcon text-decoration-none">Services <i class="bi bi-chevron-down fw-bold"></i></Link>
                 
                  </div>
                <ul class="ps-3">
                  <li class="text-decoration-none">
                    <Link
                      to="/software"  className="text-decoration-none"
                    >
                      Custom Softwares
                    </Link>
                  </li>
                  <li>
                  <Link
                  to="/web" className="text-decoration-none"
                >
                  Web Apps
                </Link>
                  </li>
                  <li>
                  <Link
                  to="/app" className="text-decoration-none"
                >
                  Mobile Apps
                </Link>
                  </li>
                  <li>
                  <Link
                  to="/cloud" className="text-decoration-none"
                >
                  Cloud Services
                </Link>
                  </li>
                  <li>
                  <Link
                  to="/graphic"
                  className="text-decoration-none"
                >
                  Graphic Designing
                </Link>
                  </li>
                  <li>
                  <Link
                  to="/digital"
                  className="text-decoration-none"
                >
                  Digital Marketing
                </Link>
                  </li>
                  <li>
                  <Link
                  to="/seo"
                  className="text-decoration-none"
                >
                  SEO
                </Link>
                  </li>
                </ul>
              </li>
              </Nav.Link>
            <Nav.Link><Link to="/projects"className="  text-decoration-none navIcon">Projects</Link></Nav.Link>
            {/* <Nav.Link><Link to="/team"className="  text-decoration-none navIcon">Team</Link></Nav.Link> */}
            <Nav.Link><Link to="/franchise"className="  text-decoration-none navIcon">Offices</Link></Nav.Link>
            <Nav.Link><Link to="/pricing"className="  text-decoration-none navIcon">Pricing</Link></Nav.Link>

            <Nav.Link><li>
              <Link style={styleLink}class="getstarted scrollto" to="/contact">
                Contact Us
              </Link>
            </li>
            </Nav.Link>
           
          </Nav>
        </Navbar.Collapse>
        </Container>

    </Navbar>
    </div>
    </header>
  );
}

export default NavBar;