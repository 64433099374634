import React from 'react';
import Navbar from "../components/NavBar"
import "../styles/Projects.css"
import img1 from "../images/cheezi.jpg"
import img2 from "../images/dh.jpg"
import img3 from "../images/dubai.jpg"
import img4 from "../images/pe.jpg"
import img5 from "../images/mf.png"
import img6 from "../images/kb.jpg"
import img7 from "../images/choice.jpg"
import img8 from "../images/bk.png"
import img9 from "../images/df.jpg"
import Footer from "../components/Footer"
// import features from "../images/features-3.png"
// import img6 from "../images/features-3.png"
import "../styles/About.css"
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
const Projects=()=>{
  const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return(
        <div>
            <Navbar/>

            <section id="portfolio" class="portfolio">

<div class="container" data-aos="fade-up">

  <header class="section-header">
    {/* <h2>Portfolio</h2> */}
    <p>Proud Projects that make us stand out</p>
  </header>

  {/* <div class="row" data-aos="fade-up" data-aos-delay="100">
    <div class="col-lg-12 d-flex justify-content-center">
      <ul id="portfolio-flters">
        <li data-filter="*" class="filter-active">All</li>
        <li data-filter=".filter-ecommerce">Ecommerce</li>
        <li data-filter=".filter-business">Business</li>
      </ul>
    </div>
  </div> */}

  <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">

    <div class="col-lg-4 col-md-6 portfolio-item filter-business">
      <div class="portfolio-wrap">
        <img src={img1} class="img-fluid" alt="Cheezi bite"/>
        <div class="portfolio-info">
          <h4>Cheezi Bite</h4>
          {/* <p>App</p> */}
           {/* <div class="portfolio-links">
            <a href="https://cheezibite.com" title="More Details"><i class="bi bi-link"></i></a>
          </div>  */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-ecommerce">
      <div class="portfolio-wrap">
        <img src={img2} class="img-fluid" alt="Dream home"/>
        <div class="portfolio-info">
          <h4>Dream Home</h4>
          {/* <p>Web</p> */}
           {/* <div class="portfolio-links">
            <a href="http://dhfurnitureinteriors.com" title="More Details"><i class="bi bi-link"></i></a>
          </div>  */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-business">
      <div class="portfolio-wrap">
        <img src={img3} class="img-fluid" alt="Best dubai landscape"/>
        <div class="portfolio-info">
          <h4>Dubai Landscape</h4>
          {/* <p>App</p> */}
          {/* <div class="portfolio-links">
            <a href="https://bestdubailandscape.com" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-ecommerce">
      <div class="portfolio-wrap">
        <img src={img4} class="img-fluid" alt="Pakistan Electronics"/>
        <div class="portfolio-info">
          <h4>Pakistan Electronics</h4>
          {/* <p>Card</p> */}
          {/* <div class="portfolio-links">
            <a href="http://pakistanelectronics.com" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-ecommerce">
      <div class="portfolio-wrap">
        <img src={img5} class="img-fluid" alt="Molty Foam"/>
        <div class="portfolio-info">
          <h4>Molty Foam</h4>
          {/* <p>Web</p> */}
          {/* <div class="portfolio-links">
            <a href="http://moltyfoamflagshipstore.com/" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-business">
      <div class="portfolio-wrap">
        <img src={img6} class="img-fluid" alt="Resturant"/>
        <div class="portfolio-info">
          <h4>Resturant</h4>
          <p>App</p>
          {/* <div class="portfolio-links">
            <a href="https://khansbaltihouseclondalkin.com/" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-business">
      <div class="portfolio-wrap">
        <img src={img7} class="img-fluid" alt="Best Choice Landscape"/>
        <div class="portfolio-info">
          <h4>Best Choice Landscape</h4>
          {/* <p>Card</p> */}
          {/* <div class="portfolio-links">
            <a href="https://www.bestchoicelandscape.ae/" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-business">
      <div class="portfolio-wrap">
        <img src={img8} class="img-fluid" alt="Burger King"/>
        <div class="portfolio-info">
          <h4>Burger King</h4>
          {/* <p>Card</p> */}
          {/* <div class="portfolio-links">
            <a href="https://www.bk.com/" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 portfolio-item filter-ecommerce">
      <div class="portfolio-wrap">
        <img src={img9} class="img-fluid" alt="Dura Foam"/>
        <div class="portfolio-info">
          <h4>Dura Foam</h4>
          {/* <p>Web</p> */}
          {/* <div class="portfolio-links">
            <a href="http://durafoammegastore.com/" title="More Details"><i class="bi bi-link"></i></a>
          </div> */}
        </div>
      </div>
    </div>

  </div>

</div>

</section>

<Footer/>
        </div>
    )
}
export default Projects