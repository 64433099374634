import React from "react";
import { Link } from "react-router-dom";
// import Why from "../images/why.png";
import features from "../images/features-3.png";
// import team1 from "../images/adrees.png";
import asma from "../images/asmaAdrees1.jpeg";
import team1 from "../images/adrees.png";
import asoftlogo from "../images/asoftlogo.png";
import badar from "../images/badar.jpg";
import "../styles/Team.css";

import "../styles/About.css";
import "../index.css";
// import mainImg from "../images/About-background.png"
import NavBar from "../components/NavBar";
import * as Icon from "react-bootstrap-icons";
// import { Button } from "react-bootstrap";
import Footer from "../components/Footer";
import about from "../images/image8.jpeg";
// import why from "../images/why.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          CEO Message
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>CEO Message</h4> */}
        <p>
          We have the latest information skills/experiences and excellent human
          resources. We promote business with our strong spirit that is to
          fulfill customers' requests at all times. Our company also creates
          what customers need to realize glamorous dream they have. As a result,
          we contribute to render an affluent society. These are our motivation.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const divStyle = {
    backgroundColor: "#003651",
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <NavBar />
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <section id="about" class="about mt-0 p-0 container">
        <div class="container-fluid" data-aos="fade-up">
          <div class="row gx-0">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center align-items-center bg-white"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="content bg-white">
                <h3 class="mt-5">Who We Are</h3>
                <h2>
                  Our service offerings enhance customer experience throughout
                  secure & highly functional end-to-end warranty management.
                </h2>
                <p class="fw-fa-italic">
                  Welcome to A Soft Solution, a leading software company that
                  specializes in providing a comprehensive range of digital
                  services. With a passion for innovation and a commitment to
                  excellence, we strive to be your trusted partner in achieving
                  your digital goals.
                   At A Soft Solution, we understand the
                  ever-evolving landscape of technology and the impact it has on
                  businesses. Our team of talented professionals combines
                  technical expertise with creative flair to deliver
                  cutting-edge solutions tailored to your unique needs. From web
                  development and app development to SEO, graphic designing, and
                  software development, we offer a diverse suite of services to
                  help you stay ahead in the digital realm.
                </p>
                <div class="text-center text-lg-start">
                  <p class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                    <span>Read More</span>
                    <i class="bi bi-arrow-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src={asoftlogo} class="img-fluid" alt="asoftsolution" />
            </div>
          </div>
        </div>
      </section>

   

      <div class="features container mt-0 p-0" id="features">
        <div class="row feature-icons mt-0 p-0" data-aos="fade-up">
          <h3>We have developed softwares for all industries...</h3>

          <div class="row">
            <div
              class="col-xl-4 text-center"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <img src={features} class="img-fluid p-4" alt="" />
            </div>

            <div class="col-xl-8 d-flex content">
              <div class="row align-self-center gy-4">
                <div class="col-md-6 icon-box" data-aos="fade-up">
                  <i class="ri-line-chart-line"></i>
                  <div>
                    <h4>Technology</h4>
                    <p>
                      A full-scale technology and solutions development partner
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="ri-stack-line"></i>
                  <div>
                    <h4>Education</h4>
                    <p>
                      Educating millions around the world with our e-learning
                      and ed-tech solutions
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="ri-brush-4-line"></i>
                  <div>
                    <h4>Business</h4>
                    <p>Developing customized solutions for finance clients</p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="ri-magic-line"></i>
                  <div>
                    <h4>Travel</h4>
                    <p>Bringing award-winning travel apps at your fingertips</p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="ri-command-line"></i>
                  <div>
                    <h4>Ecommerce </h4>
                    <p>
                      Delivering custom e-commerce platform for your business
                    </p>
                  </div>
                </div>

                <div
                  class="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <i class="ri-radar-line"></i>
                  <div>
                    <h4>Health Care</h4>
                    <p>Transforming the digital landscape of healthcare</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="services" class="services section-bg">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              From startup to enterprise, the full range of engineering services
              your company needs.
            </h2>
            <p>We Excel in Delivering Optimal Solution</p>
          </div>

          <div class="row">
            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bxl-dribbble"></i>
                  <Icon.Dribbble class="i" />
                </div>
                <h4>What we can do?</h4>

                <p>
                  We put a strong focus on the needs of your business to figure
                  out solutions that best fits your demand and nail it
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bx-file">
                    <Icon.PeopleFill className="i" />
                  </i>
                </div>
                <h4>Our Partners?</h4>
                <p>
                  Our preventive and progressive approach will help you take the
                  lead while addressing possible threats in managing data.
                </p>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bx-tachometer">
                    <Icon.PersonWorkspace className="i" />
                  </i>
                </div>
                <h4>Need a hand?</h4>
                <p>
                  Our support team is available 24/7 a day, 7 days a week and
                  can get ready for solving any of your situational rising
                  problems.
                </p>
              </div>
            </div>

            {/* <div
              class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div class="icon-box">
                <div class="icon">
                  <i class="bx bx-layer"></i>
                </div>
                <h4>Nemo Enim</h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>
              At Soft Solution we love to build and refine technologies that
              improve lives while developing great relationships along the way.
            </h2>
            {/* <p>We Excel in Delivering Optimal Solution</p> */}
          </div>

          <div class="row content">
            <div class="col-lg-6">
              <p>
                "A Soft Solution" specializes in technological and IT-related
                services such as Webdevelopment,Application development,
                software development,product engineering, warranty management,
                building cloud, infrastructure, network, etc.
              </p>
              <ul>
                <li>
                  {/* <Icon.ChevronDoubleRight className="about-icon" /> */}
                  <i class="ri-check-double-line"></i> We put a strong focus on
                  the needs of your business to figure out solutions that best
                  fits your demand and nail it.
                </li>
                <li>
                  {/* <Icon.ChevronDoubleRight className="about-icon" /> */}
                  <i class="ri-check-double-line"></i> Our preventive and
                  progressive approach will help you take the lead while
                  addressing possible threats in managing data.
                </li>
                <li>
                  {/* <Icon.ChevronDoubleRight className="about-icon" /> */}
                  <i class="ri-check-double-line"></i> Our support team is
                  available 24/7 a day, 7 days a week and can get ready for
                  solving any of your situational rising problems.
                </li>
              </ul>
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0">
              <p>
                We take pride in our strong capability for designing and
                engineering robust software using mobile, web and cloud
                technologies. Specializing in custom web applications, intuitive
                mobile apps, and robust data mining techniques, our team creates
                digital solutions that impact millions of lives.
              </p>
              <ul>
                <li>
                  {/* <Icon.ChevronDoubleRight className="about-icon" /> */}
                  <i class="ri-check-double-line"></i> We believe in nurturing
                  long term relationships with our clients. You need a partner
                  you can trust to not only bring your idea to life, but also to
                  grow with.
                </li>
                <li>
                  {/* <Icon.ChevronDoubleRight className="about-icon" /> */}
                  <i class="ri-check-double-line"></i> We have rapidly built a
                  world-class team of engineers ready to take on diverse and
                  challenging projects with an aim to create real value for our
                  partners and community.
                </li>
                {/* <li>
                  <Icon.ChevronDoubleRight className="about-icon" />
                  <i class="ri-check-double-line"></i> Our support team is
                  available 24/7 a day, 7 days a week and can get ready for
                  solving any of your situational rising problems.
                </li> */}
              </ul>
              {/* <Link to="/about" class="btn-learn-more">
                Learn More
              </Link> */}
            </div>
            <div className="text-center">
              {" "}
              <Link to="/services" class="btn-learn-more">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section id="cta" class="cta">
        {/* style={{backgroundImage:`url(${Img1})`}} */}
        <div class="container" data-aos="zoom-in">
          <div class="row">
            <div class="col-lg-9 text-center text-lg-start">
              <h3>Tell us how we can help</h3>
              <p>
                {" "}
                We love to fix complex problems and come up with innovative
                solutions. Tell us about your project and we’ll work together to
                bring you the best results. Fill out the form below and we’ll
                reach out to schedule a meeting to discuss the details..
              </p>
            </div>
            <div class="col-lg-3 cta-btn-container text-center">
              <Link to="/contact" class="cta-btn align-middle">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default About;
