import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/graphic.png";
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
export default function Graphic() {
  const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title" data-aos="fade-up">
            <h2>Graphic UI/UX Services</h2>
          </div>
            {/* <p class="mt-3">Graphic UI/UX Services</p> */}

            <h5 className='mb-4'>
            We at Nettroller help our clients to create effortlessly alluring, incompatible, and 
            innovative designs. With the help of the following services,
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="nettroller" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Logo Design</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flyer & Brochures</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>UI/UX design</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Visual Content</h3>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" class="pricing">
  <div class="container mt-5" data-aos="fade-up">
  <div class="section-title" data-aos="fade-up">
              <h2>Graphic & Logo Pricing</h2>
            </div>
    <div class="row gy-4">

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Logo Design</h3>
          <h4><sup>$</sup>100<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>5 Logo Concepts</li>
            <li><i class="bi bi-check"></i>High Resolution JPG</li>
            <li><i class="bi bi-check"></i>Vector EPS</li>
            <li><i class="bi bi-check"></i>3D Mockup View File</li>
            <li><i class="bi bi-check"></i>10 Revisions</li>
          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
        <div class="pricing-item featured">
          <h3>Print Design</h3>
          <h4><sup>$</sup>50<span> / post</span></h4>
          <ul>
            <li><i class="bi bi-check"></i>Flyer Design</li>
            <li><i class="bi bi-check"></i> Brochure Design</li>
            <li><i class="bi bi-check"></i>Poster Design</li>
            <li><i class="bi bi-check"></i>Catalog Design</li>
            <li><i class="bi bi-check"></i>Menu Design</li>

          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
        <div class="pricing-item">
          <h3>Web and App Design</h3>
          <h4><sup>$</sup>200<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>Website Design</li>
            <li><i class="bi bi-check"></i>App design</li>
            <li><i class="bi bi-check"></i>UX design </li>
            <li><i class="bi bi-check"></i> Landing Page Design</li>
            <li><i class="bi bi-check"></i> Icon Design</li>
           
          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>

    </div>

  </div>
</section>
      <Footer />
    </div>
  );
}
