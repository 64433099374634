import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/2.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Web() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title" data-aos="fade-up">
              <h2 class="mt-3">
                We Develop Mobile Applications for Native , Hybrid and Cross
                Platforms
              </h2>
            </div>
            <h5 className=" mb-5">
              With the advent of technology, our lives have become confined to
              digital assets. We make the most critical decisions of our lives
              and businesses over the Internet and mobile apps. It becomes a
              daunting task for businesses to resist in the competitive digital
              landscape as countless apps are currently lying on the Play Store
              and App Store and have limited exposure, skills, resources, and
              budget. We help our clients to build strong relationships with
              consumers
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="app development service" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Android Development using Java</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>IOS Developmemt using Swift</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Android Developmemt using Kotlin</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React Native For Hybrid Application</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flutter For Hybrid Application</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" class="pricing">
      <div class="container" data-aos="fade-up">

        <div class="row gy-4">

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="pricing-item">
              <h3>Basic</h3>
              <h4><sup>$</sup>500-2000<span></span></h4>
              <ul>
                <li><i class="bi bi-check"></i>Functional app</li>
                <li><i class="bi bi-check"></i> 2 operating systems</li>
                <li><i class="bi bi-check"></i> Source code</li>
                <li class="na"><i class="bi bi-x"></i> <span>App submission</span></li>
                <li class="na"><i class="bi bi-x"></i> <span>App icon</span></li>
                <li class="na"><i class="bi bi-x"></i> <span>Ad network integration</span></li>
                <li class="na"><i class="bi bi-x"></i> <span>Splash screen</span></li>
              </ul>
              {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
            </div>
          </div>

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="pricing-item featured">
              <h3>Standard</h3>
              <h4><sup>$</sup>2000-5000<span></span></h4>
              <ul>
                <li><i class="bi bi-check"></i>Functional app</li>
                <li><i class="bi bi-check"></i> 2 operating systems</li>
                <li><i class="bi bi-check"></i> Source code</li>
                <li><i class="bi bi-check"></i> App submission</li>
                <li><i class="bi bi-check"></i>Splash screen</li>
                <li><i class="bi bi-check"></i> App icon</li>
                <li class="na"><i class="bi bi-x"></i> <span>Ad network integration</span></li>
              </ul>
              {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
            </div>
          </div>

          <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
            <div class="pricing-item">
              <h3>Premium</h3>
              <h4><sup>$</sup>5000-10000<span> </span></h4>
              <ul>
                <li><i class="bi bi-check"></i>Functional app</li>
                <li><i class="bi bi-check"></i> 2 operating systems</li>
                <li><i class="bi bi-check"></i> Source code</li>
                <li><i class="bi bi-check"></i> App submission</li>
                <li><i class="bi bi-check"></i>Splash screen</li>
                <li><i class="bi bi-check"></i> App icon</li>
                <li><i class="bi bi-check"></i> Ad network integration</li>
                </ul>
              {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
            </div>
          </div>

        </div>

      </div>
    </section>
      <Footer />
    </div>
  );
}
