import React from "react";
import img from "../images/hero-img.png";
import {Link} from "react-router-dom"
import "../index.css";
import "../styles/Hero.css"
import { Arrow90degRight, ArrowRight } from "react-bootstrap-icons";
// import * as Icon from 'react-bootstrap-icons';
function Hero() {
  const styleLink={
    textDecoration: "none"
  }
  return (
    <>
      <section id="hero" class="d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Building powerful digital solutions for a better world</h1>
              <h2>
              Our digital solutions for a sustainable and inclusive future
                {/* We are team of talented Software Developers */}

              </h2>
              <div class="d-flex justify-content-center justify-content-lg-start">
                <p>
                <Link to="/contact" style={styleLink} class="btn-get-started scrollto ">
                  Tell us about your project<ArrowRight/>
                </Link>
                </p>
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src={img} class="img-fluid animated " alt="" />
            </div>
          </div>
        </div>
      </section>
      
      
    </>
  );
}
export default Hero;
