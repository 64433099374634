import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/5.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Seo() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Search Engine Optimization Services</h2>
            </div>
            <h5 className="mb-5">
              SEO use their understanding of these ranking factors to develop
              and implement search marketing strategies that include a balance
              of on-page, off-page, and technical best practices. An
              organization that hopes to earn and maintain high SERP rankings
              and, as a result, lots of high-quality user traffic, should employ
              a strategy that prioritizes user experience, employs
              non-manipulative ranking tactics, and evolves alongside search
              engines’ and users’ changing behaviors
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Local SEO Service</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>SEO</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex">
                    <i class="bi bi-check"></i>
                    <h3>Google Penalty Recovery</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>SEO audit</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Link Building</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Content Marketing</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" class="pricing">
  <div class="container" data-aos="fade-up">

    <div class="row gy-4">

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Location wise SEO Packages</h3>
          <h4><sup>$</sup>200<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>Local SEO package Target cities and states such as London ,England</li>
            <li><i class="bi bi-check"></i>10 keywords target</li>
            <li><i class="bi bi-check"></i>1st month onpage activity</li>
            <li><i class="bi bi-check"></i>2nd month to continue off-page 100 backlinks and social media 100 post per month</li>
          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
        <div class="pricing-item featured">
          <h3>Web Traffic SEO packages</h3>
          <h4><sup>$</sup>300<span> / month</span></h4>
          <ul>
            <li><i class="bi bi-check"></i>Website organic visits approximately 259 to 500</li>
            <li><i class="bi bi-check"></i> 10 keywords target</li>
            <li><i class="bi bi-check"></i>1st month onpage activity</li>
            <li><i class="bi bi-check"></i>2nd month to continue off-page 400 backlinks and social media 100 post per month</li>

          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="300">
        <div class="pricing-item">
          <h3>Ecommerce SEO Packages</h3>
          <h4><sup>$</sup>500<span> / month</span></h4>
          <ul>
            <li><i class="bi bi-check"></i>420 to 840 Product base SEO Packages</li>
            <li><i class="bi bi-check"></i> 80 keyword target</li>
            <li><i class="bi bi-check"></i>1st month onpage activity</li>
            <li><i class="bi bi-check"></i> Pharetra massa massa ultricies</li>
            <li><i class="bi bi-check"></i> 2nd month to continue off-page 400 backlinks and social media 100 post per month</li>
          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>

    </div>

  </div>
</section>
      <Footer />
    </div>
  );
}
