import React from 'react'
import Hero from '../components/Hero'
// import NavBar from '../components/Header'
import Nav from "../components/NavBar"
import Footer from "../components/Footer"
import Services from "../components/Home-Services"
import Values from "../components/Values"
import { Link } from 'react-router-dom'
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
const Home=()=>{
    const {pathname}=useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return(
        <div>
        {/* <NavBar/> */}
        <Nav/>
        <Hero/>
        <Services/>
       
        {/* <div class="section-header mt-5">
      <span>Social Media Packages</span>
      <h2>SEO Packages</h2>

    </div> */}

{/* <section id="horizontal-pricing" class="horizontal-pricing pt-0">
  <div class="container" data-aos="fade-up">
      <div class="row gy-4 pricing-item featured mt-4" data-aos="fade-up" data-aos-delay="200">
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h3>All Services Packages </h3>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h4><sup>$</sup>99<span> / month Starting</span></h4>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <ul>
          <li><i class="bi bi-check"></i>Web Applications</li>
          <li><i class="bi bi-check"></i> <strong>Mobile Applications</strong></li>
          <li><i class="bi bi-check"></i>Digital Marketing</li>
        </ul>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <div class="text-center"><a href="" class="buy-btn text-decoration-none"><Link class="text-dark text-decoration-none " to="/pricing">Check Now</Link></a></div>
      </div>
    </div>
    </div>
    </section> */}
    <Values/>
        <Footer/>
        </div>
    )
}
export default Home