import React from "react"
import Nav from "../components/NavBar"
import value1 from "../images/pakistan.jpg"
import value2 from "../images/england.jpg"
import value3 from "../images/canada.jpg"
import value4 from "../images/ireland.webp"
import "../styles/franchise.css"
import Footer from "../components/Footer"
import {useLocation} from "react-router-dom"
import { useEffect } from 'react'
function Franchise(){
  const {pathname}=useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <div>
            <Nav/>
            <section id="values" class="values">

<div class="container" data-aos="fade-up">

  <header class="section-header">
    {/* <h2>Our Values</h2> */}
    <p>We are working in different countries...</p>
  </header>

  <div class="row">

    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
      <div class="box">
        <img src={value1} class="img-fluid" alt=""/>
        <h3>40 Commercial, Bolan Block, DC Colony,Gujranwala , Pakistan</h3>
        {/* <span>View Location</span> */}
        {/* <p>We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.</p> */}
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div class="box">
        <img src={value2} class="img-fluid" alt=""/>
        <h3>26 High Street, Bilston, WV14 0EP, United Kingdom</h3>
        {/* <span>View Location</span> */}

        {/* <p>We have rapidly built a world-class team of engineers ready to take on diverse and challenging projects with an aim to create real value for our partners and community..</p> */}
      </div>
    </div>

   
    <div class="col-lg-4 mt-4 mt-lg-0 " data-aos="fade-up" data-aos-delay="600">
      <div class="box">
        <img src={value4} class="img-fluid" alt=""/>
        <h3>Prudent Associates, UNIT 6, TRINITY COURT, FONTHILL ROAD, DUBLIN, Dublin 22, D22 YE30, Ireland</h3>
        {/* <span>View Location</span> */}

        {/* <p>Give us a problem and we will engineer a solution! Our team of world-class engineers has developed enterprise and analytical software used by millions of users every day..</p> */}
      </div>
    </div>

  </div>

</div>

</section>
<Footer/>
        </div>
    )
}
export default Franchise