import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar.jsx";
import "../styles/Services.css";
import "../index.css";
import features from "../images/features.png";

import servicesImg from "../images/services-img.png";
import Footer from "../components/Footer.jsx";
// import * as Icon from "react-bootstrap-icons";
import {
  FaRegChartBar,
  FaTv,
  FaMobileAlt,
  FaPeopleArrows,
  FaChrome,
  FaAsymmetrik,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const Services = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <NavBar />
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">
          <div class="row gx-0">
            <div
              class="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="content">
                <h3>A Soft Solution</h3>
                <h2>
                  A Soft Solution love to build and refine technologies that
                  improve lives while developing great relationships along the
                  way.
                </h2>
                <p>
                  A Soft Solution work on cutting-edge technology and
                  enterprise solutions for solving real-world problems and help
                  businesses become bigger & better.
                </p>
                <div class="text-center text-lg-start">
                  <Link to="/contact">
                    <span class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                      Tell us more about your project!
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div
              class="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img
                src={servicesImg}
                class="img-fluid"
                alt="Best Software house in Gujranwala "
              />
            </div>
          </div>
        </div>
      </section>
      <section id="services" class="services">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Services</h2> */}
            <p>
              From startup to enterprise, the full range of engineering services
              your company needs
            </p>
          </header>

          <div class="row gy-4">
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service-box blue">
                {/* <i class="ri-discuss-line icon"><Icon. className="about-icon" /></i> */}
                <i class="icon">
                  <FaRegChartBar />
                </i>
                {/* <Icon.BsFillMouseFill className="about-icon" /> */}

                <h3>Enterprise Software Development</h3>
                <p>
                  A Soft Solution worked with 100+ companies to develop custom
                  enterprise-wide software solutions. We specialize in building
                  precisely what you need and helping you transition from the
                  software you’ve outgrown to the new model.
                </p>
                <p class="read-more">
                  <span>Read More</span>{" "}
                </p>
                <i class="bi bi-arrow-right"></i>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="service-box orange">
                <i class="icon">
                  <FaTv />
                </i>
                <h3>Web App Development</h3>
                <p>
                  A Soft Solution offer custom web app development using
                  secure, robust codebase that provides the seamless user
                  experience modern users expect.
                </p>
                <p class="read-more">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div class="service-box green">
                <i class="icon">
                  <FaMobileAlt />
                </i>

                <h3>Mobile App Development</h3>
                <p>
                  A Soft Solution provide modern mobile apps with beautiful
                  designs and intuitive user interfaces. We work with all
                  current mobile technology, including Android and iOS.
                </p>
                <p class="read-more">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div class="service-box red">
                {/* <i class="ri-discuss-line icon"></i> */}
                <i class="icon">
                  <FaPeopleArrows />
                </i>

                <h3>Digital Marketing</h3>
                <p>
                  Promotion of brands to connect with potential customers using
                  the internet and other forms of digital communication.
                </p>
                <p class="read-more">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div class="service-box purple">
                <i class="icon">
                  <FaChrome />
                </i>
                <h3>SEO</h3>
                <p>
                  Process used to optimize a website's technical configuration,
                  content relevance and link popularity so its pages can become
                  easily findable, more relevant and popular towards user search
                  queries, and as a consequence, search engines rank them
                  better.
                </p>
                <p class="read-more">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div class="service-box pink">
                <i class="icon">
                  <FaAsymmetrik />
                </i>
                <h3>Graphic Designing</h3>
                <p>
                  A Soft Solution prides itself on it's high quality designers
                  who deliver only the best graphic design services. Find the
                  right design service for you below and get design you'll love
                  today
                </p>
                <p class="read-more">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            {/* <h2>Features</h2> */}
            <p>Technologies in which we are working on...</p>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={features} class="img-fluid" alt="" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Angular</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>NodeJs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Laravell</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Wordpress</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>PHP</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default Services;
