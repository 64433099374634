import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/51.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Web() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Nav />
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
          <header class="container mt-5">
            <div class="section-title" data-aos="fade-up">
              <h2>We Thrive in Web Applications in Modern Technology</h2>
            </div>
            <h5 className="mb-5">
              {/* We use a wide range of proprietary and open-source modern
              technologies and frameworks to produce powerful and user-friendly
              applications that users can access from any modern web browser.
              Nettroller also provides native and cross-platform application
              development services for all browsers with excellent performance
              and a rich user experience. We use a wide range open-source modern
              technologies and frameworks to produce powerful and user-friendly
              applications that users can access from any modern web browser.
              Nettorller also provide native and cross-platform application
              development services for all browsers with excellent performance
              and a rich user experience. */}
              Web development is one of the major areas that businesses today,
              despite their niche, utilize for digital presence and growth and
              are constantly advancing through the latest technologies. Some
              technologies die while some can last for a long with new and
              updated features. It only makes sense that every organization
              needs to stay updated with the web development trends to stay
              evergreen in digital business platforms and search engines. To
              make things simpler, we have listed the latest technologies in web
              development to turn around your business
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid mt-4" alt="web development service"/>
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>React</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Angular</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>NodeJs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Laravel</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Django</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Flask</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Php</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>WordPress</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" class="pricing">
  <div class="container" data-aos="fade-up">

    <div class="row gy-4">

      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Service Website-1</h3>
          <h4><sup>$</sup>300-1000<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>11 pages</li>
            <li><i class="bi bi-check"></i>Mobile Responsive</li>
            <li><i class="bi bi-check"></i>Theme Customization</li>
            <li><i class="bi bi-check"></i>Blog Setup</li>
            <li><i class="bi bi-check"></i>Contact Form </li>
          </ul>
          <a href="#" class="buy-btn text-decoration-none">Buy Now</a>
        </div>
      </div>



      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>Ecommerce Website</h3>
          <h4><sup>$</sup>500-2000<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>11 pages</li>
            <li><i class="bi bi-check"></i>Mobile Responsive</li>
            <li><i class="bi bi-check"></i>11 Categories</li>
            <li><i class="bi bi-check"></i>100 Products</li>
            <li><i class="bi bi-check"></i>Contact Form </li>
          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>
      <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div class="pricing-item">
          <h3>React Application</h3>
          <h4><sup>$</sup>1000-3000<span></span></h4>
          <ul>
            <li><i class="bi bi-check"></i>10 pages</li>
            <li><i class="bi bi-check"></i>Mobile Responsive</li>
            <li><i class="bi bi-check"></i>Single Page Application</li>
            <li><i class="bi bi-check"></i>No Page Relod</li>
            <li><i class="bi bi-check"></i></li>
          </ul>
          {/* <a href="#" class="buy-btn text-decoration-none">Buy Now</a> */}
        </div>
      </div>
    </div>

  </div>
</section>
<section id="horizontal-pricing" class="horizontal-pricing pt-0">
  <div class="container" data-aos="fade-up">
      <div class="row gy-4 pricing-item featured mt-4" data-aos="fade-up" data-aos-delay="200">
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h3>Web Application</h3>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h4><sup>$</sup>499<span></span></h4>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <ul>
          <li><i class="bi bi-check"></i>Full customization</li>
          <li><i class="bi bi-check"></i> <strong>Backend with Node Js / Laravel / Djano</strong></li>
          <li><i class="bi bi-check"></i>React / Angular Front End</li>
          <li><i class="bi bi-check"></i>Database Mysql / Mongodb</li>
        </ul>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        {/* <div class="text-center"><a href="#" class="buy-btn text-decoration-none">Buy Now</a></div> */}
      </div>
    </div>
    </div>
    </section>
      <Footer />
    </div>
  );
}
