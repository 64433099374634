import React from "react";
import Nav from "../components/NavBar";
import Footer from "../components/Footer";
import service from "../images/3.jpg";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function Digital() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Nav />
      <section id="features" class="features mt-3">
        <div class="container" data-aos="fade-up">
          <header class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Digital Markeing Services</h2>
            </div>
            <h5 className="mb-5">
              With the help of different types of ads, we can help your business
              to grow. Using creative ways we can give your business different
              platforms to showcase itself.
            </h5>
          </header>

          <div class="row">
            <div class="col-lg-6">
              <img src={service} class="img-fluid" alt="nettroller" />
            </div>

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Social Media Optimization</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Google Ads</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Facebook Ads</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Instagram Ads</h3>
                  </div>
                </div>
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Youtube Ads</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="horizontal-pricing" class="horizontal-pricing pt-0">
  <div class="container" data-aos="fade-up">
      <div class="row gy-4 pricing-item featured mt-4" data-aos="fade-up" data-aos-delay="200">
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h3>Social Media Package</h3>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <h4><sup>$</sup>99<span> / month</span></h4>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <ul>
          <li><i class="bi bi-check"></i>15 hours per month</li>
          <li><i class="bi bi-check"></i> <strong>60 facebook post</strong></li>
          <li><i class="bi bi-check"></i> 30 twitter post</li>
        </ul>
      </div>
      <div class="col-lg-3 d-flex align-items-center justify-content-center">
        <div class="text-center"><a href="#" class="buy-btn text-decoration-none">Buy Now</a></div>
      </div>
    </div>
    </div>
    </section>
      <Footer />
    </div>
  );
}
